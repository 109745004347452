<template>
  <main>
    <article class="core hot row-between" v-if="hotData.length && this.$store.getters.userInfo.company_id !== 61">
      <div class="title bg-primary white md">热门推荐</div>
      <div class="hot-list row">
        <router-link
          :to="`/prodetail?id=${item.id}`"
          class="hot-item bg-white row"
          v-for="(item, index) in hotData"
          :key="index"
        >
          <el-image
            class="item-pro mr10"
            :src="item.image"
            fit="cover"
          ></el-image>
          <div class="item-text column-between">
            <div class="text-title normal line2 sm">
              {{ item.name }}
            </div>
            <make-priceformat
              :price="item.min_price"
              :subscriptSize="17"
              :firstSize="17"
              :secondSize="17"
            >
            </make-priceformat>
            <button class="bg-cartbody cartc cart-box row-center">
              立即抢购
            </button>
          </div>
        </router-link>
      </div>
    </article>
    <article class="bg-white">
      <div class="core condition">
        <make-breadcrumb></make-breadcrumb>

        <div class="pro-main row-between">
          <div class="recommend-list" v-if="this.$store.getters.userInfo.company_id !== 61">
            <div class="recommend-item mb20">
              <div class="title mb20">精品推荐</div>
              <make-storeList
                width="154"
                height="240"
                ph="0"
                pv="0"
                titleSize="14"
                margin="0"
                :join="false"
                :priceSize="14"
                :oldpriceShow="false"
                class="list"
                :goodsList="qualityData"
                v-if="qualityData.length"
              >
              </make-storeList>
              <div class="lighter ceter mb20 row-center xs" v-if="qualityData.length==0">
                暂无推荐
              </div>
            </div>
            <div class="recommend-item" v-if="false">
              <div class="title mb20">浏览记录</div>
              <make-storeList
                width="154"
                height="192"
                ph="0"
                pv="0"
                titleSize="14"
                margin="0"
                :join="false"
                :priceSize="14"
                :oldpriceShow="false"
                class="list"
              ></make-storeList>
              <div class="lighter ceter mb20 row-center xs">暂无记录</div>
            </div>
          </div>
          <div class="pro-list"  v-if="goodsList.length" :style="'width: '+(this.$store.getters.userInfo.company_id == 61 ? '100%' : '980px')">
            <div class="pro-condition row-between normal nr">
              <ul class="row">
                <li
                  :class="{ active: screen.default }"
                  @click="
                    (screen.sales = ''),
                      (screen.default = true),
                      (screen.price = ''),
                      getGoodsList()
                  "
                >
                  <span>综合排序</span>
                </li>
                <li
                  class="row-center"
                  :class="{ active: screen.sales }"
                  @click="
                    (screen.sales = screen.sales == 'asc' ? 'desc' : 'asc'),
                      (screen.default = false),
                      (screen.price = ''),
                      getGoodsList()
                  "
                >
                  <span>销量</span>
                  <img
                    :class="{
                      rotate: screen.sales && screen.sales == 'desc',
                    }"
                    :src="
                      require(screen.sales
                        ? '@/assets/icon/icon-arrow9.png'
                        : '@/assets/icon/icon-arrow4.png')
                    "
                    alt=""
                  />
                </li>
                <li class="row-center">
                  <span>评论数</span
                  ><img src="@/assets/icon/icon-arrow4.png" alt="" />
                </li>
                <li class="row-center">
                  <span>推荐</span
                  ><img src="@/assets/icon/icon-arrow4.png" alt="" />
                </li>
                <li
                  class="row-center price-box"
                  :class="{ active: screen.price }"
                  @click="
                    (screen.default = false),
                      (screen.sales = ''),
                      (screen.price = screen.price == 'asc' ? 'desc' : 'asc'),
                      getGoodsList()
                  "
                >
                  <span>价格</span>
                  <div class="column arrow">
                    <em :class="{ active: screen.price == 'asc' }"></em>
                    <em :class="{ active: screen.price == 'desc' }"></em>
                  </div>
                </li>

                <li class="row-center">
                  <el-dropdown @command="setHdsx">
                    <span class="el-dropdown-link">
                      {{ hdsx.name
                      }}<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="0">全部</el-dropdown-item>
                      <el-dropdown-item command="2">限时折扣</el-dropdown-item>
                      <el-dropdown-item command="3">满减活动</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </li>
              </ul>
              <div class="row input-box">
                <input type="text" placeholder="请输入关键字" />
                <button type="primary" class="mr5">搜索</button>
                <button type="reset">清空</button>
              </div>
            </div>
            <make-storeList
              width="236"
              height="425"
              ph="17"
              pv="17"
              titleSize="14"
              margin="6"
              :priceSize="20"
              :oldpriceShow="false"
              class="list mb10"
              :prolists="true"
              :goodsList="goodsList"
            ></make-storeList>
            <div class="row-center">
              <make-pagination
                @resetList="resetList"
                :orderObject="orderObject"
              ></make-pagination>
            </div>
          </div>
          <el-empty description="暂无商品" class="mt30 flex1" v-else></el-empty>
        </div>
      </div>
    </article>
  </main>
</template>
<script>
import MakeBreadCrumb from "@/components/MakeBreadCrumb";
import MakeStoreList from "@/components/MakeStoreList";
export default {
  name: "",
  props: {},
  components: {
    "make-breadcrumb": MakeBreadCrumb,
    "make-storeList": MakeStoreList,
  },
  data() {
    return {
      categoryList: [],
      categoryOne: 0, //一级分类
      categoryTwo: 0, //二级分类
      categoryThree: 0, //三级分类
      count: 0, //总条数
      goodsList: [], //商品列表
      currentPage: 0, //当前页
      // 筛选条件
      screen: {
        default: true, //综合
        sales: "", //销量
        price: "", //价格
      },
      screenCurrent: 0,
      categoryId: "", //当前筛选ID
      hotData: [], //热门列表
      page_no: 1,
      qualityData: [], //精品列表
      orderObject: {},
      keyword: "",
      hdsx: {
        id: 0,
        name: "全部",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
    setHdsx(command) {
      if (command == 3) {
        this.hdsx.id = 3;
        this.hdsx.name = "满减活动";
      } else if (command == 2) {
        this.hdsx.id = 2;
        this.hdsx.name = "限时折扣";
      } else {
        this.hdsx.id = 0;
        this.hdsx.name = "全部";
      }
      this.getGoodsList(this.categoryId);
    },
    /**
     * @description 获取菜单筛选
     * @returns void
     */
    getCategoryList() {
      this.$api.getCategoryApi().then((res) => {
        this.categoryList = res.data;
        let categoryId = this.categoryList[0].id || "";
        if (this.categoryId) categoryId = this.categoryId;
        if (categoryId) this.getGoodsList(categoryId);
      });
    },
    /**
     * @description 获取商品列表
     * @returns void
     */
    getGoodsList(categoryId = "") {
      //   if (categoryId) this.categoryId = categoryId;
      this.$api
        .getGoodsListApi({
          //   platform_cate_id: this.categoryId,
          page_size: 20,
          activity_type: this.hdsx.id,
          keyword: this.keyword,
          page_no: this.page_no,
          sort_by_sales: this.screen.sales,
          sort_by_price: this.screen.price,
        })
        .then((res) => {
          let { lists, count, page_size: size, page_no: page } = res.data;
          this.orderObject = res.data;
          this.orderObject = {
            page,
            size,
            count,
            lists,
          };
          this.goodsList = lists;
        });
    },
    resetList(e) {
      this.page_no = e;
      this.getGoodsList();
    },

    /**
     * @description 获取热门/精品推荐列表
     */
    async getGoodsListByColumnId() {
      let {
        code: hotCode,
        data: { lists: hotData },
      } = await this.$api.getGoodsListByColumnIdApi({
        column_id: 2,
        page_size: 3,
      });
      let {
        code: qualityCode,
        data: { lists: qualityData },
      } = await this.$api.getGoodsListByColumnIdApi({
        column_id: 1,
        page_size: 3,
      });
      if (hotCode == 1) this.hotData = hotData;
      if (qualityCode == 1) this.qualityData = qualityData;
    },
  },
  created() {
    this.getGoodsListByColumnId();
    let { categoryOne, categoryTwo, categoryThree, categoryId, keyword } =
      this.$route.query;
    this.categoryOne = categoryOne || 0;
    this.categoryTwo = categoryTwo || 0;
    this.categoryThree = categoryThree || 0;
    this.categoryId = categoryId || 0;
    this.keyword = keyword || "";
    this.getGoodsList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang="scss" scoped>
main {
  .core {
    width: 1170px;
  }

  .hot {
    padding: 33px 0;

    .title {
      width: 46px;
      height: 150px;
      padding: 28px 14px 0;
    }

    .hot-list {
      .hot-item {
        border: $-solid-border;
        width: 359px;
        height: 150px;
        margin-right: 18px;
        padding: 20px;
        align-items: normal;

        &:last-child {
          margin-right: 0;
        }

        .item-pro {
          width: 140px;
          height: 108px;
        }

        .item-text {
          align-items: normal;

          .text-title {
            line-height: 20px;
            height: 40px;
          }

          .cart-box {
            width: 90px;
            height: 32px;
            border: $-solid-cartborder;
            cursor: pointer;
          }
        }
      }
    }
  }

  .condition {
    padding-top: 20px;
    padding-bottom: 40px;

    .condition-box {
      padding: 0px 20px;
      border: $-solid-border;

      .condition-t {
        height: 54px;
        border-bottom: $-dashed-border;

        .title {
          margin-right: 35px;
        }

        .t-content {
          padding: 0 8px;
          height: 28px;
          border: $-solid-border;
        }
      }

      .condition-b {
        padding: 15px 0;
        align-items: flex-start;
        line-height: 1.8;

        .title {
          margin-right: 43px;
          flex-shrink: 0;
        }

        .brand-list {
          span {
            margin-right: 60px;
            cursor: pointer;

            &.active,
            &:hover {
              color: $-color-primary;
            }
          }
        }
      }
    }
  }
}

.pro-main {
  align-items: flex-start;

  .recommend-list {
    width: 177px;

    .recommend-item {
      border: $-solid-border;
      padding: 0 10px;

      .title {
        height: 45px;
        line-height: 45px;
        border-bottom: $-solid-border;
      }

      /deep/.list {
        margin: 0;
        padding: 0 0 10px;

        .store-item {
          box-shadow: none;

          &:hover {
            .item-title {
              color: $-color-primary;
            }
          }

          .item-title {
            margin-bottom: 0;
          }

          .item-pro {
            margin-bottom: 5px;
          }
        }
      }
    }
  }

  .pro-list {
    width: 980px;

    .pro-condition {
      height: 46px;
      background: #f3f5f8;
      border: $-solid-border;

      ul {
        li {
          padding: 0 15px;
          cursor: pointer;

          span {
            margin-right: 5px;
          }

          .rotate {
            transform: rotate(180deg);
          }

          &.active {
            color: $-color-primary;
          }

          &.price-box {
            .arrow {
              position: relative;
              top: 02.5px;
            }

            em {
              transform: rotate(90deg);
              line-height: 1;
              font-size: 14px;
              width: 10px;
              height: 8px;
              color: #9e9e9e;
              font-weight: bold;
              background-image: url("~@/assets/icon/icon-arrow1.png");
              background-size: contain;
              background-repeat: no-repeat;

              &.active {
                color: $-color-primary;
                background-image: url("~@/assets/icon/icon-arrow10.png");
              }

              &:first-child {
                transform: rotate(270deg);
                margin-bottom: 5px;
              }
            }
          }
        }
      }

      .input-box {
        height: 30px;
        padding-right: 33px;

        input {
          border: 1px solid #d8d8d8;
          border-right: none;
          background-color: $-color-white;
          padding: 0 15px;
          height: inherit;
          width: 137px;
        }

        button {
          width: 73px;
          height: 30px;
          color: $-color-white;
          cursor: pointer;
        }
      }
    }

    /deep/ .list {
      padding-bottom: 20px;

      .store-item {
        .item-pro {
          margin-bottom: 10px;
        }

        .item-title {
          margin-bottom: 5px;
        }

        .item-cart {
          margin-top: 10px;
        }
      }
    }
  }
}
</style>
